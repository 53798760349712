// importing thing we need
import AOS from "aos";
import Splide from "@splidejs/splide";
import membersData from "../_data/members";
import autoScroll from "../_data/autoScroll";

// variables
let animation = false;

// Selectors
const landingVideo = document.querySelector(".landing-video");
const landingText = document.querySelectorAll(".text-over  > .text");

// Initializing on landing video
landingVideo.play();
// Initializing on scroll animation
AOS.init();

// Fucntions

// This function will change text

const closeMemberModal = (id) => {
  const selectedPopup = document.querySelector(`#member-${id}`);
  document.querySelector("body").classList.remove("stop-scroll");
  selectedPopup.classList.toggle("zoomOut");
  animation = true;
  setTimeout(() => {
    selectedPopup.classList.toggle("hidden-pop");
    selectedPopup.classList.toggle("zoomOut");
    animation = false;
  }, 500);
};

const changeText = (textIndex) => {
  landingText.forEach((item, index) => {
    item.classList.add("text-hidden");
    if (index === textIndex) {
      item.classList.toggle("text-hidden");
    }
  });
};

const watchVideo = () => {
  const time = Math.round(landingVideo.currentTime * 10) / 10;

  if (time === 0) changeText(0);
  else if (time === 2.5) changeText(1);
  else if (time === 6) changeText(2);
  else if (time === 9.6) changeText(3);

  window.requestAnimationFrame(watchVideo);
};

const showMemberDetails = (id) => {
  if (animation) return;
 
  const selectedPopup = document.querySelector(`#member-${id}`);
  console.log(selectedPopup)
  console.log(id)
  selectedPopup.classList.toggle("hidden-pop");
  document.querySelector("body").classList.add("stop-scroll");
};

const closeMemberModalOutside = (e, id) => {
  if (e.target.classList.contains("mem-popup")) {
    closeMemberModal(id);
  }
};

// Setting function globally
window.showMemberDetails = showMemberDetails;
window.closeMemberModal = closeMemberModal;
window.closeMemberModalOutside = closeMemberModalOutside;

// event listeners

// Calling watchVideo function
// window.requestAnimationFrame(watchVideo);


// For logo slide animation
 
// const autoSplide = new Splide(".splide", {
//   type: "loop",
//   drag: "free",
//   focus: "center",
//   perPage: 3,
//   arrows: false,
//   pagination: false,
//   autoScroll: {
//     speed: -1,
//     pauseOnHover: false,
//     pauseOnFocus: false,
//   },
// });

// autoSplide.mount(window.splide.Extensions);
