module.exports = [
  {
    id: 304972815,
    name: "Patrick Baljak",
    creatorName: "Patrick Baljak",
    nickName: "Patrick",
    role: "Co-Founder / Management",
    about:
      "Lerne Patrick kennen - der sich auf Social Media, Werbung und Storytelling spezialisiert hat. Mit einem Abschluss in Business Communication und jahrelanger Erfahrung in der Branche hat er ein tiefes Verständnis für die Bedürfnisse seiner Kunden entwickelt. Seine Fähigkeit, sich in die Lage seiner Kunden hineinzuversetzen, ihre Ziele zu verstehen und maßgeschneiderte Lösungen anzubieten, sind ein essenzieller Bestandteil seiner Arbeit. Mit einem talentierten Team stellt er sicher, dass seine Kunden den besten Service erhalten. Er glaubt, dass eine erfolgreiche Kampagne auf einer starken Story basieren sollte und setzt sich dafür ein, dass jede Geschichte einzigartig und authentisch ist.",
    src: "./assets/images/patrick.jpg",
    social: [
      { name: "instagram", path: "https://www.instagram.com/1hvgo/" },
      {
        name: "linkedin",
        path: "https://www.linkedin.com/in/patrick-baljak-7b534215a/",
      },
      { name: "email", path: "mailto:patrick@4clout.ch" },
    ],
  },

  {
    id: 385596247,
    name: "Leonard Stucki",
    creatorName: "Leonard Stucki",
    nickName: "Leonard",
    role: "Co-Founder / Creative",
    about:
      " Lerne Leo kennen - ein talentierter und ehrgeiziger Content Creator, der bereit ist, die Extrameile zu gehen, um seine Zuschauer zu begeistern. Sein Erfolg bei den Swiss Comedy Awards im Jahr 2022 ist ein Beweis für seine Fähigkeit, das Publikum zum Lachen zu bringen und gleichzeitig eine qualitativ hochwertige Produktion zu liefern. Er legt großen Wert darauf, Videos zu produzieren, die sich von der Masse abheben und einzigartig sind. Er ist nicht zufrieden damit, einfach nur normalen Content zu produzieren. Stattdessen arbeitet er hart daran, immer etwas Neues und Interessantes zu kreieren, um seine Fans zu unterhalten und zu inspirieren.",
    src: "./assets/images/leonard.jpg",
    social: [
      { name: "tiktok", path: "https://www.tiktok.com/@leonard.khalifa" },
      { name: "instagram", path: "https://www.instagram.com/leonardkhalifa/" },
      {
        name: "youtube",
        path: "https://www.youtube.com/@LeonardKhalifa69/featured",
      },
      { name: "email", path: "mailto:leonard@4clout.ch" },
    ],
  },

  {
    id: 987453216,
    name: "Cameron Weijmer",
    creatorName: "Cameron Weijmer",
    nickName: "Cameron",
    role: "Web Developer",
    about:
      "Lerne Cameron kennen - ein erfahrener Webentwickler mit umfassenden Kenntnissen in HTML, CSS und JavaScript. Sein tiefgehendes Verständnis für Webentwicklung und Programmierung sowie seine rasche Anpassungsfähigkeit an neue Technologien und Trends machen ihn zu einem Experten. Die Expertise in der Erstellung komplexer Webseiten und Anwendungen hat ihm einen exzellenten Ruf in der Branche eingebracht. Cameron zeichnet sich besonders durch seine schnelle Identifizierung von Problemen und die effektive Bereitstellung von Lösungen aus, was ihn zu einem unverzichtbaren Teammitglied macht",
    src: "./assets/images/cameron.jpg",
    social: [
      { name: "instagram", path: "https://www.instagram.com/cameron.weijmer/" },
      { name: "email", path: "mailto:hello@weboptix.ch" },
    ],
  },
  {
    id: 741258963,
    name: "Alen Krajinovic",
    creatorName: "Alen Krajinovic",
    nickName: "Alen",
    role: "Foto- und Videograf",
    about:
      " Lerne Alen kennen - einen begnadeten Fotografen und Videoproduzenten, der für seinen herausragenden Content geschätzt wird. Seine Expertise erstreckt sich über eine breite Palette an Bereichen, einschließlich Eventfotografie, Standesamt-Fotografie und Boudoir-Fotografie. Neben der Fotografie hat Alen auch im Bereich Aftermovies und Werbevideos schon diverse Klienten überzeugt. Seine Produktionen sind stets kreativ, einzigartig und spiegeln das gewünschte Image perfekt wider. Mit einem untrüglichen Gespür für Ästhetik und einer Leidenschaft für Perfektion kann Alen selbst die anspruchsvollsten Kunden zufriedenstellen.",
    src: "./assets/images/alen.jpeg",
    social: [
      {
        name: "instagram",
        path: "https://www.instagram.com/bunte_.farben/?igshid=YmMyMTA2M2Y%3D",
      },
    ],
  },
  {
    id: 623874519,
    name: "Aaron Faas",
    creatorName: "Aaron Faas",
    nickName: "Aaron",
    role: "Foto- und Videograf",
    about:
      "Lerne Aaron kennen - ein Multitalent in Sachen Foto- und Videografie. Diversen Brands hat er bereits durch seinen hochklassigen Content seinen Stempel aufgedrückt. Zudem sammelt er auch heute noch als Teil des Social-Media-Teams eines renommierten Radiosenders reichlich Erfahrung und hat zahlreiche Projekte erfolgreich umgesetzt. Sein Portfolio umfasst die Erstellung von Inhalten für Unternehmen, Aftermovies für besondere Anlässe, Architektur-, Fashion- und Konzertfotografie sowie Werbeproduktionen für Restaurants und Kleidermarken.",
    src: "./assets/images/aaron.jpeg",
    social: [
      {
        name: "instagram",
        path: "https://www.instagram.com/cxroni.studio/?igshid=YmMyMTA2M2Y%3D",
      },
    ],
  },

  {
    id: 12,
    name: "Oliver Schmid",
    creatorName: "Oliver Schmid",
    nickName: "Oliver",
    role: "Producer",
    about:
      "Lerne Oliver kennen – unseren Allrounder, der unser dynamisches Team tatkräftig unterstützt. Er vereint diverse Teilbereiche der Produktion und hat sich zu einer wichtigen Stütze entwickelt, der gerne auch die Extrameile geht, um unserer Kundschaft ein einwandfreies Endprodukt zu liefern.",
    src: "./assets/images/oli.jpg",
    social: [
      { name: "instagram", path: "https://www.instagram.com/mronar.official/" },
    ],
  },
];
